import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as L from 'leaflet';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomerService } from '@app/services/customer.service';
import { Customer } from '@app/services/models/customer.model';
import { Router } from '@angular/router';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { RecaptchaModule, RecaptchaErrorParameters, RecaptchaFormsModule } from 'ng-recaptcha';
import { environment } from '@environment/environment';

interface StepperElement {
  stepId: string;
  progress: string;
  isFinalStep: boolean;
  initFunction: () => void
}

@Component({
  selector: 'app-customer-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss','../common.scss']
})
export class CustomerFormComponent implements AfterViewInit {
  private map: L.Map;
  currentActiveStep: StepperElement;
  isLoading: boolean;
  zoneNotFound: boolean;
  recordedRegister: any = {};
  recaptchaSiteKey = environment.recaptchaKey;
  steps: StepperElement[] = [
    {
      stepId: 'captcha',
      progress: '0',
      isFinalStep: false,
      initFunction: () => {},
    },
    {
      stepId: 'houseOwner',
      progress: '20',
      isFinalStep: false,
      initFunction: ()=> {

      }
    },
    {
      stepId: 'houseType',
      progress: '40',
      isFinalStep: false,
      initFunction: ()=> {

      }
    },
    {
      stepId: 'address',
      progress: '60',
      isFinalStep: false,
      initFunction: ()=> {
        this.createMap();
        this.zoneNotFound = false;
      }
    },
    {
      stepId:'billingRange',
      progress: '80',
      isFinalStep: false,
      initFunction: ()=> {

      }
    },
    {
      stepId: 'customerInfo',
      progress: '100',
      isFinalStep: true,
      initFunction: () => {

      }
    },
  ];

  addressForm = new FormGroup({
    zipCode: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
  })

  captchaForm = new FormGroup({
    recaptchaReactive: new FormControl(null, Validators.required),
  });

  customerInfoForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.pattern(/^([a-z]|[A-Z]|'|á|é|í|ó|ú|ñ|ü|Á|É|Í|Ó|Ú|Ñ|Ü|\s|\'|\-)+$/)
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.pattern(/^([a-z]|[A-Z]|'|á|é|í|ó|ú|ñ|ü|Á|É|Í|Ó|Ú|Ñ|Ü|\s|\'|\-)+$/)
    ]),
    phone: new FormControl('', [ Validators.required ]),
    email: new FormControl('', [ Validators.required, Validators.email])
  });

  @ViewChild('stepperProgress')
  stepperProgress: ElementRef;

  constructor(
    private readonly customerService: CustomerService,
    private readonly router: Router,
    private readonly library: FaIconLibrary,
   // private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.currentActiveStep = this.steps[0];
    this.library.addIconPacks(fas);
  }
  public resolved(captchaResponse: any): void {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
  async onZipChange($event: any) {
    if(this.isLoading) return;
    try {
      this.isLoading = true;
      const zipCode = $event.target.value;
      const response = await this.customerService.findZipCode(zipCode);
      if(response != null) {
        const geojsonFeature = JSON.parse(response.geometry);
        let geojsonLayer = L.geoJSON(geojsonFeature, {
          style: {
            fillColor: '#FF6735',
            color: '#0047bf'
          }
        });
        geojsonLayer.addTo(this.map);
        this.map.fitBounds(geojsonLayer.getBounds());
        this.zoneNotFound = false;
      } else {
        this.zoneNotFound = true;
      }
    } catch(error) {

    } finally {
      this.isLoading = false;
    }

  }

  ngAfterViewInit(): void {
  }

  createMap() {
    this.map = L.map('map', {
      center: L.latLng(39.8282, -98.5795),
      zoom: 3,
      zoomControl: false,
      attributionControl: false
    });
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3
    });
    tiles.addTo(this.map);
  }

  backStep() {
    let currentActiveStep = this.currentActiveStep;
    let elementIndex = this.steps.findIndex(({stepId})=> stepId == currentActiveStep.stepId);
    let progress = currentActiveStep.progress;
    if(elementIndex > 0) {
      let previousStep = this.steps[elementIndex - 1];
      this.stepperProgress.nativeElement.classList.add(`progress_${previousStep.progress}`)
      this.stepperProgress.nativeElement.classList.remove(`progress_${progress}`);
      this.currentActiveStep = previousStep;
    }
  }

  completeStep(completedStepId: string, value: any) {
   /* if(completedStepId == 'houseOwner') {
      this.recaptchaV3Service
      .execute('completeStepHouseOwner')
      .subscribe((token) => {
        console.log(token);
      });
    }*/
    if(value instanceof FormGroup) {
      const formGroup = value as FormGroup
      Object.assign(this.recordedRegister, formGroup.getRawValue())
    } else {
      let record = {[completedStepId] : value};
      Object.assign(this.recordedRegister, record)
    }

    let elementIndex = this.steps.findIndex(({stepId})=> stepId == completedStepId);

    if (elementIndex >= 0) {
      let completedStepInfo = this.steps[elementIndex];
      if (completedStepInfo.isFinalStep) {
        let previousStep = this.steps[elementIndex-1];
        let progress = completedStepInfo.progress;
        this.stepperProgress.nativeElement.classList.remove(`progress_${previousStep.progress}`)
        this.stepperProgress.nativeElement.classList.add(`progress_${progress}`);
        this.isLoading = true;
        this.customerService
            .createCustomer(this.recordedRegister as Customer)
            .subscribe({
              next: (customer) => {
                this.router.navigate(['register'], { queryParams: { email: customer.email }});
              },
              error: () => {
                this.isLoading = false;
              }
            })
        //customerService
      } else {
        let nextStep = this.steps[elementIndex+1];
        let progress = nextStep.progress;
        this.currentActiveStep = nextStep;
        this.stepperProgress.nativeElement.classList.remove(`progress_${completedStepInfo.progress}`)
        this.stepperProgress.nativeElement.classList.add(`progress_${progress}`)
        setTimeout(() =>  nextStep.initFunction(), 200)

      }
    }
  }

  get zipCodeControl() {
    return this.addressForm.get('zipCode');
  }
  get addressControl() {
    return this.addressForm.get('address');
  }
}
