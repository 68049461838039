export const environment = {
  appPreffix: 'dev',
  recaptchaPvtKey: "6LfXqdwpAAAAAOcw7qw0nwbu_G2-QsiZwAbkQD5S",
  recaptchaKey: "6LfXqdwpAAAAAHvgrz8WnXhY7cKxSktzLEZldGun",
  firebaseConfig: {
    apiKey: "AIzaSyAyp2ZHWReKi-m11qIBwfH5wUdXnnRatzw",
    authDomain: "ideo-renova-energy.firebaseapp.com",
    projectId: "ideo-renova-energy",
    storageBucket: "ideo-renova-energy.appspot.com",
    messagingSenderId: "8283988399",
    appId: "1:8283988399:web:28e8edfa36404974228f5a",
    measurementId: "G-8B8NHBY1LN"
  }
}
