import { Injectable, inject } from '@angular/core';
import { Firestore, collection, getDoc, getDocs, query, updateDoc, where } from '@angular/fire/firestore';
import { environment } from '@environment/environment';
import { Customer } from './models/customer.model';
import { Observable, from, of } from 'rxjs';
import { doc, setDoc } from "firebase/firestore";
import { ZipCode } from './models/zipcode.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private firestore: Firestore = inject(Firestore);
  constructor() {}

  createCustomer(customer: Customer): Observable<Customer> {
    const customerId = crypto.randomUUID();
    customer.customerId = customerId;
    customer.createdAt = new Date();
    const document = doc(this.firestore,  `${environment.appPreffix}-customers`, customerId);
    return from(setDoc(document, customer).then(() => customer));
  }

  async findZipCode(zipCode: string): Promise<ZipCode | null> {
    let geojsonRef = doc(this.firestore,  `${environment.appPreffix}-zip-codes-geojson`, zipCode);
    const geometry = await getDoc(geojsonRef);
    if(geometry.exists()) {
      return geometry.data() as ZipCode;
    }
    return null;
  }

  async attachAccountInformation(email: string, userId: string) {
    const q = query(collection(this.firestore,`${environment.appPreffix}-customers`), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, { userId })
    });
  }
}
