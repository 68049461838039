import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideFirebaseApp, initializeApp, } from '@angular/fire/app';
import { environment } from '@environment/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RECAPTCHA_SETTINGS } from "ng-recaptcha";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    FontAwesomeModule
  ],
  providers: [{provide: RECAPTCHA_SETTINGS, useValue: environment.recaptchaPvtKey }],
  bootstrap: [AppComponent]
})
export class AppModule { }
