<div class="app-toolbar">
  <div class="app-icon"></div>
</div>
<div class="register-form">
  <div class="align-center">
    <div class="welcome-message">Bienvenidos a Renova</div>
    <div class="register-button">Registrate</div>
  </div>
  <span>
    Para darte de alta en nuestra plataforma, por favor completa la siguiente información
  </span>
  <div class="spinner-container" *ngIf="isLoading">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <span>Por favor espere...</span>
  </div>
</div>
<form class="register-form" [formGroup]="customerRegisterForm">
  <div class="form-field">
    <label>Correo Electrónico</label>
    <input id="email" type="text" placeholder="Correo Electrónico" formControlName="email" />
  </div>
  <div class="form-field">
    <label>Contraseña</label>
    <input id="password" type="password" placeholder="Contraseña" formControlName="password" />
  </div>
  <div class="form-field">
    <label>Confirmar Contraseña</label>
    <input id="passwordConfirm" type="password" placeholder="Confirmar Contraseña" formControlName="passwordConfirm" />
  </div>
  <div class="button-panel">
    <button
    id="registerForm"
    class="primary"
    [disabled]="isLoading || !customerRegisterForm.valid"
    (click)="createCustomerAccount()">Registrate</button>
  </div>

  <div class="social-network">
    <div>
      <span>Ó registrate usando</span>
    </div>
    <div class="network-panel">
      <!--
      <div class="network facebook" (click)="loginUsingFacebook()"></div>
      <div class="network apple" (click)="loginUsingApple()"></div>
      -->
      <div class="network google" (click)="loginUsingGoogle()"></div>
    </div>
  </div>
</form>

