<div class="app-toolbar">
  <div class="app-icon"></div>
</div>
<div class="stepper-bar">
  <div *ngIf="currentActiveStep.stepId != 'captcha'">
    <div class="stepper-button" (click)="backStep()" >
      <div class="stepper-back">
        <fa-icon [icon]="['fas', 'arrow-left']" size="xs" class="stepper-icon"></fa-icon>
        <div class="stepper-text">Regresar</div>
      </div>
    </div>
  </div>

</div>
<div class="stepper">
  <div class="stepper-bar">
    <div class="stepper-progress" #stepperProgress></div>
  </div>
  <div class="stepper-content">
    <!--6 steps-->
    <h1>Ahorra miles de dólares con energías renovables</h1>
    <div class="step active-step" #captcha *ngIf="currentActiveStep.stepId == 'captcha'">
      <form [formGroup]="captchaForm">
        <div class="button-panel">
          <re-captcha class="center" (errored)="onError($event)" formControlName="recaptchaReactive" siteKey="{{recaptchaSiteKey}}"></re-captcha>
        </div>

        <div class="button-panel">
          <button class="primary" [disabled]="captchaForm.invalid" (click)="completeStep('captcha',null)">Continuar</button>
        </div>
      </form>
    </div>
    <div class="step active-step" #houseOwner *ngIf="currentActiveStep.stepId == 'houseOwner'">
      <h2>¿Eres propietario de casa?</h2>
      <p>Nota: Este beneficio solo aplica a propietarios de inmuebles. Si estas rentando un propiedad no eres elegible para este beneficio</p>
      <div class="button-panel">
        <button id="houseOwner"
        class="primary"
        (click)="completeStep('houseOwner', true)">SI</button>
        <button id="noHouseOwner" class="secondary">NO</button>
      </div>
    </div>
    <div class="step" #houseType *ngIf="currentActiveStep.stepId == 'houseType'">
      <h2>¿Cual es el tipo de casa?</h2>
      <p>Ofrecemos el tipo de sistema que se ajuste a la necesidad de tu hogar</p>
      <div class="choice-card-container">
        <div class="choice-card">
          <div class="choice-card-image">
            <img class="SINGLE_FAMILY" src="/assets/SINGLE_FAMILY.png">
          </div>
          <button id="singleFamily" class="accent" (click)="completeStep('houseType', 'SINGLE_FAMILY')">Single Family</button>
        </div>
        <div class="choice-card">
          <div class="choice-card-image">
            <img class="MOBILE_HOME" src="/assets/MOBILE_HOME.png">
          </div>
          <button id="mobileHome" class="accent" (click)="completeStep('houseType', 'MOBILE_HOME')">Mobile Home</button>
        </div>
      </div>
    </div>
    <div class="step" #address *ngIf="currentActiveStep.stepId == 'address'">
      <h2>¿Cual es el código postal de tu casa?</h2>
      <p>Nos permite poder manejar información acerca de la fuente de luz solar</p>
      <form [formGroup]="addressForm">
        <div class="form-field">
          <label>Zip Code</label>
          <input id="zipCode" type="text" (change)="onZipChange($event)" placeholder="Ingresa el Zip Code" formControlName="zipCode" />
          <div class="form-field-error" *ngIf="zoneNotFound">
            No pudimos encontrar el Zip Code
          </div>
          <div class="h-spinner-container" *ngIf="isLoading">
            <div class="spinner"></div>
            <span>Validando Zip...</span>
          </div>
        </div>
        <div class="form-field">
          <label>Dirección</label>
          <textarea id="address" placeholder="Ingresa la Dirección" formControlName="address"></textarea>
        </div>
        <div class="map-wrapper">
          <div id="map"></div>
        </div>
      </form>
      <div class="button-panel">
        <button
        id="confirmAddress"
        class="accent"
        [disabled]="isLoading || !addressForm.valid"
        (click)="completeStep('address', addressForm)">Confirmar Dirección</button>
      </div>

    </div>
    <div class="step" #billingRange *ngIf="currentActiveStep.stepId == 'billingRange'">
      <h2>¿Cuando es tu consumo eléctrico mensual?</h2>
      <p>Nos permite poder calcular tu ahorro en electricidad</p>
      <div class="button-panel">
        <button id="below100" class="primary" (click)="completeStep('billingRange', 'BELOW_100')">Menor 100 USD</button>
        <button id="above100" class="secondary" (click)="completeStep('billingRange', 'ABOVE_100')">Mayor 100 USD</button>
      </div>
    </div>
    <div class="step" #customerInfo *ngIf="currentActiveStep.stepId == 'customerInfo'">
      <h2>Para tener información de como contactarte compaternos los siguientes datos</h2>
      <p>Nos permite manteneros en contacto</p>
      <div class="spinner-container" *ngIf="isLoading">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <span>Por favor espere...</span>
      </div>
      <form [formGroup]="customerInfoForm">
        <div class="form-field">
          <label>Nombres</label>
          <input id="firstName" type="text" placeholder="Nombres" formControlName="firstName" />
        </div>
        <div class="form-field">
          <label>Apellidos</label>
          <input id="lastName" type="text" placeholder="Apellidos" formControlName="lastName" />
        </div>
        <div class="form-field">
          <label>Teléfono</label>
          <input id="phone" type="text" placeholder="Teléfono" formControlName="phone" />
        </div>
        <div class="form-field">
          <label>Correo Electrónico</label>
          <input id="email" type="text" placeholder="Correo Electrónico" formControlName="email" />
        </div>
      </form>
      <div class="button-panel">
        <button
        id="registerForm"
        class="primary"
        [disabled]="isLoading || !customerInfoForm.valid"
        (click)="completeStep('customerInfo', customerInfoForm)">Quiero mi Ahorro Eléctrico</button>
      </div>
    </div>
  </div>
</div>
