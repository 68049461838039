import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { Auth, GoogleAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { UserCredential, signInWithEmailAndPassword } from '@firebase/auth';
import { CustomerService } from '@app/services/customer.service';

@Component({
  selector: 'app-customer-register',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './customer-register.component.html',
  styleUrls: ['./customer-register.component.scss','../common.scss']
})
export class CustomerRegisterComponent {
  isLoading: boolean;

  private auth: Auth = inject(Auth);

  constructor(private customerService: CustomerService) {}

  customerRegisterForm = new FormGroup({
    email: new FormControl('', [ Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required,]),
    passwordConfirm: new FormControl('', [Validators.required]),
  }, this.confirmedValidator('password', 'passwordConfirm'));



  loginUsingFacebook() {}
  loginUsingApple() {}

  confirmedValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (abstractControl: AbstractControl):  ValidationErrors | null =>  {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);
      if(control == null || matchingControl == null) return null;
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return null;
      }
      if (control.value !== matchingControl.value) {
        return { confirmedValidator: true };
      } else {
        return null;
      }
    };
  }

  async createCustomerAccount() {
    if(this.customerRegisterForm.valid) {
      try {
        this.isLoading = true;
        const { email, password } = this.customerRegisterForm.getRawValue();
        const response = await signInWithEmailAndPassword(this.auth, email as string, password as string);
        await this.associateAccount(response);
      } catch(throwable) {
        console.error(`Error signing In with Email`, throwable);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async loginUsingGoogle() {
    if(this.isLoading) return;
    try {
      this.isLoading = true;
      const response = await signInWithPopup(this.auth, new GoogleAuthProvider());
      await this.associateAccount(response);
    } catch(error) {
      console.error(`Error signing In with google with error`, error);
    } finally {
      this.isLoading = false;
    }
  }

  async associateAccount({ providerId, user }: UserCredential) {
    const { providerData } = user;
    const maybeInfo = providerData.find((userInfo) => userInfo.providerId == providerId);
    if(maybeInfo != null) {
      const { email, uid} =  maybeInfo;
      await this.customerService.attachAccountInformation(email as string, uid);
    }
  }
}
